import React, { useEffect } from "react";
import { navigate } from "gatsby";

export default function FourOhFourPage() {
  useEffect(() => {
    navigate("/");
  }, []);

  return null;
}
